import Vue from 'vue'
import VueRouter from "vue-router"
import Vuex from "vuex"
import createVueRouter from './router/'
import createVueStore from './libs/store'
import App from './views/app.vue'
import './scss/common.scss'
import Vconsole from 'vconsole'

Vue.use(VueRouter);
Vue.use(Vuex);

new Vconsole();

new Vue({
	el: "#app",
	router : createVueRouter(VueRouter),
	store: createVueStore(Vuex),
	render: h => h(App)
})
