const configTerm = [
  {
    path: '/smart-term',
    name: 'term',
    redirect: '/',
    component: resolve => { require(['@/views/term/termIndex.vue'], resolve) },
    children: [
      { path: 'main', name: 'smart-term-main', meta: {title: 'smart-term-main'}, component: resolve => require(['@/views/term/termHome.vue'], resolve)},
      { path: 'result-success', name: 'smart-term-result-success', meta: {title: 'smart-term-reuslt-success'}, component: resolve => require(['@/views/term/termResult/success.vue'], resolve)},
      { path: 'result-fail', name: 'smart-term-result-fail', meta: {title: 'smart-term-reuslt-fail'}, component: resolve => require(['@/views/term/termResult/fail.vue'], resolve)},
      { path: 'add-card', name: 'smart-term-add-card', meta: {title: 'smart-term-add-card'}, component: resolve => require(['@/views/term/termAddCard.vue'], resolve)},
      { path: 'update-info', name: 'smart-term-update-info', meta: {title: 'smart-term-update-info'}, component: resolve => require(['@/views/term/termUpdateInfo.vue'], resolve)},
      { path: 'card-list', name: 'smart-term-card-list', meta: {title: 'smart-term-card-list'}, component: resolve => require(['@/views/term/termCardList.vue'], resolve)},
      
    ]
  }
];

export default configTerm;
