const configApp = [
  {
    path: '/smart-app',
    name: 'app',
    redirect: '/',
    component: resolve => { require(['@/views/app/appIndex.vue'], resolve) },
    children: [
      { path: 'main', name: 'smart-app-main', meta: {title: 'smart-app-main'}, component: resolve => require(['@/views/app/appMain.vue'], resolve)},
      { path: 'card-list', name: 'smart-app-card-list', meta: {title: 'smart-app-card-list'}, component: resolve => require(['@/views/app/appCardList.vue'], resolve)},
      { path: 'add-card', name: 'smart-app-add-card', meta: {title: 'smart-app-add-card'}, component: resolve => require(['@/views/app/appAddCard.vue'], resolve)},
      { path: 'add-code', name: 'smart-app-add-code', meta: {title: 'smart-app-add-code'}, component: resolve => require(['@/views/app/appAddCode.vue'], resolve)},
      { path: 'bank-list', name: 'smart-app-bank-list', meta: {title: 'smart-app-bank-list'}, component: resolve => require(['@/views/app/appBankList.vue'], resolve)},
      { path: 'complete-toast', name: 'smart-app-complete-toast', meta: {title: 'smart-app-complete-toast'}, component: resolve => require(['@/views/app/appCompleteToast.vue'], resolve)},
      { path: 'deal-loading', name: 'smart-app-deal-loading', meta: {title: 'smart-app-deal-loading'}, component: resolve => require(['@/views/app/appDealLoading.vue'], resolve)},
      { path: 'result-fail', name: 'smart-app-result-fail', meta: {title: 'smart-app-result-fail'}, component: resolve => require(['@/views/app/appResult/fail.vue'], resolve)},
      { path: 'result-success', name: 'smart-app-result-success', meta: {title: 'smart-app-result-success'}, component: resolve => require(['@/views/app/appResult/success.vue'], resolve)},
      { path: 'update-info', name: 'smart-app-update-info', meta: {title: 'smart-app-update-info'}, component: resolve => require(['@/views/app/appUpdateInfo.vue'], resolve)},
    ]
  }
];

export default configApp;
