import routerApp from './modules/app';
import routerTerm from './modules/term';
import routerPC from './modules/pc';

let routerConfig = {
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "welcome",
      meta: { title: "pageTitle", },
      component: resolve => require(["../views/welcome/welcome.vue"], resolve)
    },
    ...routerApp,
    ...routerTerm,
    ...routerPC,
    {
      path: "*",
      redirect: "/"
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
}

let be4Each = function (router) {
  router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
      window.document.title = to.meta.title;
    }
    next();
  });
};

let afterEach = function (router) {
  router.afterEach((to, from) => { });
};

let createVueRouter = function (VueRouter) {
  let router = new VueRouter(routerConfig);
  be4Each(router);
  afterEach(router);
  return router;
};

export { createVueRouter };

export default createVueRouter;
