
let Config = {
    state:{
      returnUrl: '',    // 返回商家
    },
    mutations: {
      setReturnUrl (state, url) {
        state.returnUrl = url;
      }
    },
    actions: {

    },
    getters: {

    }
}
let createVueStore = function(Vuex){
    let store = new Vuex.Store(Config)
   
    return store;
}



export { createVueStore } ;

export default createVueStore ;