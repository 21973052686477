const configTerm = [
  {
    path: '/smart-pc',
    name: 'pc',
    redirect: '/',
    component: resolve => { require(['@/views/pc/pcIndex.vue'], resolve) },
    children: [
      { path: 'main', name: 'smart-pc-main', meta: {title: 'smart-pc-main'}, component: resolve => require(['@/views/pc/pcHome.vue'], resolve)},
      { path: 'result-success', name: 'smart-pc-result-success', meta: {title: 'smart-pc-reuslt-success'}, component: resolve => require(['@/views/pc/pcResult/success.vue'], resolve)},
      { path: 'result-fail', name: 'smart-pc-result-fail', meta: {title: 'smart-pc-reuslt-fail'}, component: resolve => require(['@/views/pc/pcResult/fail.vue'], resolve)},
      { path: 'add-card', name: 'smart-pc-add-card', meta: {title: 'smart-pc-add-card'}, component: resolve => require(['@/views/pc/pcAddCard.vue'], resolve)},
      { path: 'update-info', name: 'smart-pc-update-info', meta: {title: 'smart-pc-update-info'}, component: resolve => require(['@/views/pc/pcUpdateInfo.vue'], resolve)},
    ]
  }
];

export default configTerm;
